body {
  margin:0;
  color:#000;
  background-color: #000;
  font:15px/18px Arial, Helvetica, sans-serif;
  background:$white;
  border: 1px solid #4d4d4d;
  min-width:300px;
}
input[type=submit]:hover{cursor:pointer;}
img {border-style:none;}
a {
  text-decoration:none;
  color:#607e86;
}
a:hover {
  color:#808080 !important;
  text-decoration:none;
}
a:active {background-color: transparent;}
input,
textarea,
select {
  vertical-align:middle;
  color:#808080;
  font:100% Arial, Helvetica, sans-serif;
}
hr {
  clear: both;
}
form, fieldset {
  margin:0;
  padding:0;
  border-style:none;
}
#wrapper{
  width:100%;
  overflow:hidden;
  position:relative;
}
#header.container{
  background:#bbb79a;
  width:100%;
  padding:9px 0 7px 0;
  left:0;
  top:0;
  z-index:25;
  border: 1px solid #4d4d4d;
  border-bottom:4px solid #9c976d;
  -moz-box-sizing:border-box;
  -webkit-box-sizing:border-box;
  box-sizing:border-box;
  position:fixed;
    height: 47px;
}
.container-holder{
  max-width:968px;
  margin:0 auto;
  text-align:center;
    position: relative;
}
.social-box{
  position: absolute;
    top: 5px;
    left: 0;
}
.social-box img{
  vertical-align:top;
}
.logo, .logo2{
  display:inline-block;
  text-indent:-9999px;
  overflow:hidden;
  width:310px;
  height:16px;
  margin:5px 0 0 5px;
    background-size: 100% auto;
}
.logo {
    background:url(../images/cuc_tcc-logo_wht_310x16.png) no-repeat;
}
.logo2 {
    display: none;
}
.logo a, .logo2 a{
  display:block;
  height:100%;
}
.form-search{
  position: absolute;
  top: -1px;
  right: 0;
  width:174px;
  margin:0 5px 0 0;
  padding:0 6px 0 5px;
  -webkit-border-radius: 10px;
  border-radius: 10px;
  height: 27px;
  input[type="text"]{
    margin:0;
    padding:6px 0 0 3px;
    float:left;
    width:136px;
    color:#939393;
    border:0;
    background:0;
    font-style:italic;
    -webkit-border-radius:0;
    border-radius: 0;
    -webkit-box-shadow:inset 0 0 0 0 rgba(0,0,0,0.3);
    box-shadow:inset 0 0 0 0 rgba(0,0,0,0.3);
  }
}
.form-search input[type="submit"]{
  float:right;
  margin:4px 2px 0 0;
  padding:0;
  width:16px;
  height:17px;
  border:0;
  font-size:0;
  line-height:0;
  overflow:hidden;
  text-indent:-9999px;
  background:url(../images/bg-submit.png) no-repeat;
}
input[type="submit"]:hover{cursor:pointer;}
input[type="submit"]::-moz-focus-inner{
  padding:0;
  border:0;
}
#main{
  max-width:962px;
  margin:0 auto;
  padding: 0 0 121px 0;
}
.visual-holder{
  position:relative;
  padding:68px 0 0 ;
}
.visual-block{
  position:relative;
  margin:0 auto;
  max-width:962px;
}
.banner-box img{
  width:962px;
  max-width:962px;
}
.banner-box .banner2{
  display:none;
}
.banner-box  a{
  display:block;
  max-height:106px;
  font-size:34px;
  line-height:36px;
  color:$white;
  text-transform:uppercase;
  overflow:hidden;
  height:100%;
}
.banner-box  a img{
  vertical-align:top;
}
.visual-block .nav-box{
  min-height:1px;
}
.social-icons{
  overflow:hidden;
  padding:0 0 7px;
  position:absolute;
  right:12px;
  top:18px;
}
.social-icons ul{
  float:right;
  margin:0;
  padding:0;
  list-style:none;
}
.social-icons li{
  float:left;
  margin:0 0px 0 2px;
}
.social-icons li a{
  float:left;
  width:30px;
  height:31px;
}
.social-icons li a img{
  display: block;
  // @include border-radius(15px);
}
.social-icons li.twitter a{
  width:30px;
  height:30px;
}
.social-icons li.tube a{
  width:30px;
  height:31px;
}
.social-icons li.linkedin a{
  width:30px;
  height:31px;
}
.social-icons li.rss a{
  width:30px;
  height:30px;
}
.navigation-block:after{
  display:block;
  clear:both;
  content:'';
}
.navigation-block{
  padding:0 23px 0 0;
  position:absolute;
  right:0;
  top:70px;
}
.navigation-block .btn-opener{
  float:right;
  font-size:20px;
  line-height:23px;
  color:$white;
  text-transform:uppercase;
  padding:0 35px 0 0;
  background:url(../images/btn-opener.png) no-repeat 100% 1px;
  position:relative;
  outline: none;
    text-decoration:none;
}
.btn-search-opener{
  display:none;
}
.visual-holder.popup-active .navigation-block .btn-opener,
.navigation-block .btn-opener:hover{
  background:url(../images/btn-opener-h.png) no-repeat 100% 1px;
  text-decoration:none;
  color:#000000 !important;
}
.navigation-block .btn-opener:before{
  content: '';
  position:absolute;
  right:-8px;
  bottom:-35px;
  background:url(../images/arrow2.png) no-repeat;
  width:42px;
  height:22px;
  z-index:10;
  display:none;
}
.visual-holder.popup-active .navigation-block .btn-opener:before{
  display:block;
}
.navigation-block .btn-opener span{
  padding:0 15px 0 0;
}
.drop-down2{
  display:none;
}
.drop-down{
  background:#bab799;
  position:absolute;
  left:50%;
  top:100%;
  margin:20px 0 0 -481px;
  z-index:10;
  -moz-box-sizing:border-box;
  -webkit-box-sizing:border-box;
  box-sizing:border-box;
  padding:18px 2.6% 0;
  display:none;
  width: 962px;
}
.drop-down2 .heading-holder,
.drop-down .heading-holder{
  overflow:hidden;
  border-bottom:2px solid $white;
  padding:0 0 8px;
  font-size:27px;
  line-height:28px;
  text-transform:uppercase;
}
.drop-down2 .heading-holder strong,
.drop-down .heading-holder strong{
  color:$white;
  font-weight:normal;
  padding:19px 0 0;
  float:left;
}
.drop-down .holder{
  overflow:hidden;
  padding:31px 0 12px;
}
.drop-down .holder .col{
  float:left;
  width:33%;
}
.drop-down .holder .col .opener-btn{
  display:block;
  color:$white;
  font-size:16px;
  line-height:23px;
  padding:0 0 13px;
}
.drop-down .holder ul{
  margin:0;
  padding:0;
  list-style:none;
  list-style-image: url(data:0);
  position:static;
  li {
    vertical-align:top;
    font-size:21px;
    line-height:23px;
    padding:0 0 13px;
    a {
      color:$white;
    }
  }
}
.drop-down2 .btn-close2,
.drop-down .btn-close{
  float:right;
  background:url(../images/close-btn.png) no-repeat;
  width:42px;
  height:42px;
  margin:0;
}
.arrow-holder{
  position:absolute;
  top:-30px;
  max-width:960px;
  margin:0 auto;
  left:0;
}
.arrow-holder:before{
  content: '';
  position:absolute;
  right:60px;
  top:0;
  width:42px;
  background:#c20;
  height:22px;
}
#sidebar{
  float:left;
  margin:0;
  padding:6px 7px 0 0;
}
.side-nav{

}
.side-nav ul.mobile-nav{display:none;}
.side-nav ul{
  margin:0;
  padding:0;
  list-style:none;
  font-size:13px;
  line-height:16px;
}
.side-nav > ul > li{
  vertical-align:top;
  font-weight:bold;
  border-top:2px solid #ccc;
  padding:8px 0 26px 1px;
}
.side-nav > ul > li > a{
  color:#8a8560;
  text-transform:uppercase;
  display:block;
}
.side-nav ul li ul{
  padding:10px 0 0;
}
.side-nav ul li ul li{
  padding:0 0 1px;
}
.side-nav ul li ul ul{
  padding:5px 0 4px;
}
.side-nav ul li a{
  color:#8a8560;
  outline: none;
}
.side-nav ul li ul a{
  padding:8px 0 8px 20px;
  display:block;
  color:#615e43;
}
.side-nav > ul > li.expanded > a{
  text-decoration:underline;
}
.side-nav ul li ul li.expanded > a,
.side-nav ul li ul a:hover{
  background:#faf8e9;
  text-decoration:none;
}
.side-nav ul li ul ul li{
  padding:0 0 0 20px;
}
.side-nav ul li ul ul a{
  color:#8a8560;
  font-weight:normal;
  padding:9px 0 8px 17px;
}
.main-holder{
  max-width:954px;
  margin:0 auto;
  overflow:hidden;
}
#content{
  float:right;
  padding:8px 0 0;
  line-height:20px;
}

#content a, #content a:hover, #content a:active {
  text-decoration:underline;
}

#crumbs {
  color: #b3b3b3;
  a {
    color: #b3b3b3;
  }
}

#breadcrumbs span {
  color:#b3b3b3;
  a {
    color:#b3b3b3;
    text-decoration: underline;
  }
  @media screen and (max-width: 768px){
    font-size:11px;
  }
}

#content .heading-box{
  border-bottom:5px solid #bab799;
  padding:18px 0 6px;
  margin:0 0 10px;
}
#content .heading-box h1{
  color:#9d986d;
  font-size:22px;
  line-height:25px;
  margin:0;
  font-weight: bold;
}

.flexComp, .flexCompImg, .alignleft, .alignright, .aligncenter{
  clear: both;
  }

.flexComp .caption p:last-of-type {
  display: inline;
  }
.flexComp .caption p, .flexCompImg .caption p {
  margin: 0 !important;
  }

.flexComp, .flexCompImg, .alignright {
  margin:5px 0 0 26px;
  float:right;
  }
.alignleft, .alignright, .aligncenter{
    max-width: 300px;
}
#content .alignleft img, #content .alignright img, #content .aligncenter img{
    width:100%;
}

.alignleft{
  float:left;
  margin:5px 26px 0 0;
}
.aligncenter img,
.alignright img,
.alignleft img{
  display:block;
  margin:0 0 5px;
  border: 1px solid #B3B4B4;
}

.aligncenter{text-align:center;margin:0 auto 20px; float: none;}
.aligncenter img{display:inline;}


.caption{
  text-align:left;
  display:block;
  margin-bottom: 5px;
    color: #808080;
}
.caption.center{
  text-align:center;
}
#content p{
  margin:0 0 20px;
  font-size:15px !important;
}
.carousel-home{
  margin:0 0 28px;
}
.carousel-home .carousel-control{
  background:url(../images/arrow-gallery-l.png) no-repeat;
    background-position: center center !important;
  width:23px;
  height:42px;
  border:none;
  opacity: 1;
  top:50%;
}
.carousel-home .carousel-control.right{
  background:url(../images/arrow-gallery-r.png) no-repeat;
}

.content-box{
  max-width:962px;
  margin:0 auto;
  line-height:20px;
}
.content-box .text-box{
  max-width:962px;
  margin:0 auto;
  li {
    font-size: 9pt; line-height: 17pt !important;
  }
  p {
    padding: 0px;
  }
  ul {
    padding: 28px 22px 0 28px;
  }
}

.inner-text {
  max-width: 916px;
  margin: 0 auto;
}
#interiorCopy {
    font: 15px/18px Arial, Helvetica, sans-serif;
    line-height: 21px;

    a {
      color:#607e86;
    }
}
.visual-panel{
  padding: 48px 0 0 13px;
  -moz-box-sizing:border-box;
  -webkit-box-sizing:border-box;
  box-sizing:border-box;
}
.visual-panel .image-box{
  border: 1px solid #999;
  padding:6px;
  max-width:240px;
  margin:24px 0 10px;
}
.visual-panel p{
  margin:0 0 10px;
}
.visual-panel h2{
  color:#9d9870;
  font-size:18px;
  line-height:20px;
  text-transform:uppercase;
  margin:0 0 18px;
  font-weight: bold;
}
.visual-panel .col{
  -moz-box-sizing:border-box;
  -webkit-box-sizing:border-box;
  box-sizing:border-box;
  padding:0 3% 33px 0;
  position:relative;
}
.visual-panel .col img{
  vertical-align:top;
}
.visual-panel .col a.learn{
  color:#0171bb;
  text-transform:uppercase;
  font-weight:bold;
  padding:0 18px 0 0;
  background:url(../images/arrow.png) no-repeat 100% center;
}
.visual-panel .col a.learn:hover{
  background:url(../images/arrow9.png) no-repeat 100% center;
}

.flexComp a.learn{
  color:#0171bb;
  text-transform:uppercase;
  font-weight:bold;
  padding:0 18px 0 0;
  background:url(../images/arrow.png) no-repeat 100% center;
}
.flexComp a.learn:hover{
  background:url(../images/arrow9.png) no-repeat 100% center;
}
#footer{
  background:#bbb79a;
}
.footer-holder{
  max-width:1000px;
  margin:0 auto;
  padding:16px 0 15px;
}
.footer-holder > * {
  padding-left: 25px;
  padding-right: 25px;
  }
.footer-holder address{
  display:block;
  margin:0 0 0 17px;
  font-size:15px;
  line-height:18px;
}
.footer-holder .image-list{
  overflow:hidden;
  padding:15px 0 0;
}
.footer-holder .image-list ul{
  float:left;
  margin:0;
  padding:0;
  list-style:none;
}
.footer-holder .image-list li{
  float:left;
  max-width:104px;
  margin:0 0 12px 19px;
}
.footer-holder .image-list li a{
  display:block;
  border-top:6px solid #808080;
  padding:7px 0 0 0;
}
.footer-holder .image-list li a:hover{
  border-top:6px solid #333333;
  background:$white;
}
.footer-holder .image-list li a.pomona-college:hover{
  border-top:6px solid #20438F;
}
.footer-holder .image-list li a.claremont-graduate-university:hover{
  border-top:6px solid #CE0F24;
}
.footer-holder .image-list li a.claremont-university-consortium:hover{
  border-top:6px solid #592C82;
}
.footer-holder .image-list li a.scripps-college:hover{
  border-top:6px solid #42695C;
}
.footer-holder .image-list li a.claremont-mckenna-college:hover{
  border-top:6px solid #892230;
}
.footer-holder .image-list li a.harvey-mudd-college:hover{
  border-top:6px solid #ECA900;
}
.footer-holder .image-list li a.pitzer-college:hover{
  border-top:6px solid #F06500;
}
.footer-holder .image-list li a.keck-graduate-institute:hover{
  border-top:6px solid #00657F;
}

.footer-holder .image-list .image-holder{
  border: 0;
  display:block;
  margin:0;
}
.footer-holder .image-list img{
  vertical-align:top;
  width:100%;
}

a.addthis_button_compact.at300m {
width:auto !important;
}

#interiorCopy h2, #home-body-copy .text-box h2 {
  font-size: 22px;
  line-height: 25px;
  }

#interiorCopy h3, #home-body-copy .text-box h3 {
  font-size: 20px;
  line-height: 23px;
  }

#interiorCopy h4, #home-body-copy .text-box h4 {
  font-size: 18px;
  line-height: 21px;
  }

#interiorCopy h5, #home-body-copy .text-box h5 {
  font-size: 15px;
  line-height: 18px;
  }

// #interiorCopy h3.ui-accordion-header a, #interiorCopy h3.ui-accordion-header a:hover, #interiorCopy h3.ui-accordion-header a:active {
//     text-decoration: none;
// }

@media only screen and (max-width: 1004px){
  .footer-holder .image-list{
    width: 504px;
    margin: 0 auto;
  }
  .social-box{
    margin:0 0 0 5px;
  }
}

@media only screen and (max-width: 980px){
  .visual-holder{
    padding:45px 0 0;
  }
}

@media only screen and (max-width: 960px){
  .drop-down{
    left:0;
    margin:20px 0 0 0;
    width:100%;
  }
  .main-holder{
    padding:0 1%;
  }
  .carousel-home{
    padding:0 2%;
  }
  .content-box{
    padding:0 2%;
    -moz-box-sizing:border-box;
    -webkit-box-sizing:border-box;
    box-sizing:border-box;
  }
  .carousel-home .carousel-control{
    left:3%;
  }
  .carousel-home .carousel-control.right{
    left:auto;
    right:3%;
  }
}
@media only screen and (max-width: 767px){
    #header.container {
    height: auto;
    }
  .visual-holder{
    padding:38px 0 0;
  }
  .visual-panel .image-box{
    max-width:450px;
    margin:24px auto 10px;
  }

  .side-nav ul{display:block;}
  .side-nav ul.mobile-nav{display:block;}
  .side-nav ul li{vertical-align:top;font-weight:bold;border-top:2px solid #ccc;padding:0;margin:0;}
  .side-nav ul li a{color:#8a8560;text-transform:uppercase;display:block;background:url(../images/arrow7.png) no-repeat 99% center;padding:5px 1px;font-weight:bold;}

  .social-icons{
    position:absolute;
    right:2%;
    padding:0;
    top:auto;
  }
  .banner-box a{
    font-size:20px;
    line-height:23px;
  }
  .navigation-block{
    position:absolute;
    right:12px;
    bottom:10px;
    padding:0 10px 0 0;
    top:88px;
  }
  body{
    padding:0;
    font: 15px/18px Arial, Helvetica, sans-serif;
  }
  #content{
    width:100%;
    float:left;
    padding:41px 2% 0;
    -moz-box-sizing:border-box;
    -webkit-box-sizing:border-box;
    box-sizing:border-box;
  }
  #content img{ /* make sure to change next rule if this one changes */
    vertical-align:top;
    width:100%;
    margin:0 0 15px;
    float:none;
    border: 1px solid #b3b4b4;
  }

    #content img.gcsc-branding-img-noclear, #content .fsForm img { /* we don't want our normal img handling screwing up the google logo for custom search */
        vertical-align: auto !important;
    width: auto !important;
    margin: auto !important;
    float: none !important;
    border: none !important;
    }

  #content .aligncenter img{
    width:auto;
  }

  .carousel-inner{
    border: 1px solid #b3b4b4;
  }
  #sidebar{
    width:100%;
    -moz-box-sizing:border-box;
    -webkit-box-sizing:border-box;
    box-sizing:border-box;
    padding:0 2%;
  }
  .form-search{
    display:none;
  }

  .logo, .logo2{
    width:176px;
    height:10px;
        background-size: 100% auto;
        background-repeat:no-repeat;
        margin-left: -2px;
  }
    .logo {
        background:url(../images/cuc_tcc-logo_wht_176x10.png) no-repeat;
    }
  .visual-panel .col{
    width:100%;
    padding:0 0 20px;
    margin:0 0 20px;
    -moz-box-sizing:border-box;
    -webkit-box-sizing:border-box;
    box-sizing:border-box;
  }
  .visual-panel .col img{
    width:100%;
  }
  .visual-panel{
    padding:0;
  }
  .carousel-home{
    padding:0 2%;
    margin:40px 0 10px;
  }
  .carousel-home .carousel-control{
    /*left:33px;*/
        width: 3%;
        background-size: contain;
  }
  .carousel-home .carousel-control.right{
        width: 3%;
        background-size: contain;
    /*left: auto;
    right:33px;*/
  }
  .visual-panel .image-box{
  }
  #main{
    padding:0 0 30px;
  }
  .visual-panel h2{

  }
  .navigation-block .btn-opener{
    width:26px;
    height:21px;
    padding:8px 15px 8px 8px;
        margin-top: -18px;
        margin-right: -15px;
    background-size: 26px 21px;
        background-position: center;
  }
  .visual-holder.popup-active .navigation-block .btn-opener,
  .navigation-block .btn-opener:hover{
    background:url(../images/btn-opener-h.png) no-repeat 100% 1px;
    background-size: 26px 21px;
    background-position: center;
  }
  .navigation-block .btn-opener span{
    display:none;
  }
  #content .heading-box{
    padding:5px 0 6px;
  }
  .drop-down2 .btn-close2,
  .drop-down .btn-close{
    background-size:19px 19px;
    width:19px;
    height:19px;
  }
  .drop-down2 .heading-holder,
  .drop-down .heading-holder{
    font-size:14px;
    line-height:16px;
  }
  .drop-down2 .heading-holder strong,
  .drop-down .heading-holder strong{
    padding:0;
  }
  /* Commented Out - Punchlist Item 5256 */
  /*
  .visual-panel .col a{
    color:#9e9a69;
    background:url(../images/arrow4.png) no-repeat 100% 3px;
    padding:0 15px 0 0;
  }
  */
  .drop-down{
    z-index:11;
    margin:8px 0 0 0;
  }
  .drop-down .holder .col .opener-btn{
    display:block;
    color:$white;
    font-size:14px;
    padding:10px 0 9px;
    border-bottom:1px solid #e0e1e2;
  }
  .drop-down .holder .col.expanded .opener-btn{
    background:url(../images/arrow6.png) no-repeat 97% 11px;
  }
  .drop-down .holder .col{
    width:100%;
    float:none;
  }
  .drop-down .holder ul{
    padding:10px 0 11px;
  }
  .drop-down .holder ul.js-slide-hidden{
    display:none !important;
  }
  .drop-down .holder ul {
    padding: 0;
    li{
      @media only screen and (min-width: 768px){
        line-height:17px;
        padding:0 0 5px;
      }
      padding: 10px 0 9px;
      border-bottom: 1px solid #e0e1e2;
      a {
        font-size: 14px;
      }
    }
  }
  .drop-down .holder{
    padding:6px 0 12px;
  }
  .navigation-block .btn-opener:before{
    width:31px;
    height:9px;
    background-size:31px 9px;
    bottom: -18px;
    right: -4px;
  }
  .btn-search-opener{
    display:block;
        position: fixed;
    background:url(../images/search.png) no-repeat;
    width:60px;
    height:16px;
    top:12px;
        right:6px;
  }
  #header .btn-search-opener:before{
    content: '';
    position:fixed;
    background:url(../images/arrow2.png) no-repeat;
    background-size:31px 9px;
    width:31px;
    height:9px;
    top: 41px;
    right:20px;
    z-index:10;
    display:none;
  }
  #header.popup-active2 .btn-search-opener:before{
    display:block;
  }
  #header.popup-active2 .btn-search-opener,
  .btn-search-opener:hover{
    /*background:url(../images/bg-search-btn-h.png) no-repeat;*/
  }
  .drop-down2{
    background:#bab799;
    position:fixed;
    left:0;
    top:48px;
    z-index:10;
    width:100%;
    -moz-box-sizing:border-box;
    -webkit-box-sizing:border-box;
    box-sizing:border-box;
    padding:18px 2.6% 0;
    display:none;
    height: 100px;
  }
  .form-search.mobile{
    display:block;
        position: relative;
    float:none;
    // overflow:hidden;
    background:none;
    border:none;
    padding:10px 0 12px;
    width:100%;
    -webkit-border-radius: 0;
    border-radius: 0;
  }
  .form-search.mobile input[type="text"]{
    margin:0;
    padding:4px 10px;
    -moz-box-sizing:border-box;
    -webkit-box-sizing:border-box;
    box-sizing:border-box;
    float:left;
    width:90%;
    border:0;
    background:$white;
    border: 1px solid #a3a19d;
    height:auto;
    -webkit-border-radius:10px;
    border-radius:10px;
  }
  .form-search.mobile input[type="submit"]{
    background:url(../images/btn-search.png) no-repeat;
    width:15px;
    height:15px;
    margin:4px 0 0 3%;
    float:left;
  }
  .footer-holder{
    text-align:center;
  }
  .footer-holder address{
    display:inline-block;
    text-align:center;
    margin:0 15px;
    font-size:11px;
  }
  .footer-holder .image-list li{
    display:inline-block;
    float:none;
    margin:0 7px 12px;
  }
    .social-box{
      top: 2px;
    }
}
@media only screen and (max-width: 640px){
  .aligncenter,
  .alignright,
  .alignleft{
    display:block;
    float:none;
    margin:0 auto 10px;
    max-width:350px;
  }
}
@media only screen and (max-width: 560px){
  .banner-box img{
    display:none;
  }
  .banner-box img.banner2{
    display:block;
    width:556px;
  }
}
@media only screen and (max-width: 504px){
  .footer-holder .image-list{
    width: 360px;
  }
  .footer-holder .image-list li{
    max-width:70px;
  }
}

@media only screen and (max-width: 480px){
  #content .heading-box h1{
    font-size:14px;
    line-height:16px;
  }

  #interiorCopy h2, #home-body-copy .text-box h2 {
    font-size: 14px;
    line-height: 16px;
    }

  #interiorCopy h3, #home-body-copy .text-box h3 {
    font-size: 13px;
    line-height: 15px;
    }

  #interiorCopy h4, #home-body-copy .text-box h4 {
    font-size: 12px;
    line-height: 14px;
    }

  #interiorCopy h5, #home-body-copy .text-box h5 {
    font-size: 11px;
    line-height: 13px;
    }

  #content{
    
  }
  #content p{
    margin:0 0 8px;
  }
  .side-nav ul{
    font-size:12px;
  }
  .content-box{
    font-size:11px;
  }
  .visual-panel h2{
    font-size:14px;
    line-height:16px;
    border-bottom:2px solid #9e9a69;
    padding:12px 0 3px;
    margin:0 0 8px;
  }
  .visual-panel .image-box{
    margin:10px 0 9px;
  }
}
@media only screen and (max-width: 420px){
  .banner-box a{
  }
  .footer-holder .image-list{
    width: 100%;
  }
    .alignright, .alignleft, .aligncenter{
    max-width:250px;
  }
}

@media only screen and (max-width: 380px){
  .footer-holder .image-list li{
    max-width:60px;
    margin:0 5px 12px;
  }

}

@media only screen and (max-width: 345px){
  .banner-box img.banner2{
    width:160%;
  }
  .navigation-block{
    top:76px;
  }
  .drop-down{
    margin:7px 0 0;
    top:100%;
  }
  .drop-down2{
    top:100%;
    margin:7px 0 0;
  }
  .visual-block{
    height:103px;
  }
  .navigation-block .btn-search-opener:before{
    bottom:-21px;
  }

}
@media only screen and (max-width: 340px){


}

@media only screen and (-webkit-min-device-pixel-ratio: 1.5),
only screen and (-moz-min-device-pixel-ratio: 1.5),
only screen and (-o-min-device-pixel-ratio: 3/2),
only screen and (min-device-pixel-ratio: 1.5),
only screen and (min-resolution: 144dpi) {
    img.highResolutionAvailable {
        visibility:hidden;
        }
    img.highResolutionAvailable_ready {
        visibility:visible !important;
        }
    .logo{
      background:url(../images/cuc_tcc-logo_wht_620x32.png) no-repeat;
        background-size: 100% auto;
        }
}

@media only screen and (max-width: 767px) and (-webkit-min-device-pixel-ratio: 1.5),
only screen and (max-width: 767px) and (-moz-min-device-pixel-ratio: 1.5),
only screen and (max-width: 767px) and (-o-min-device-pixel-ratio: 3/2),
only screen and (max-width: 767px) and (min-device-pixel-ratio: 1.5),
only screen and (max-width: 767px) and (min-resolution: 144dpi) {
    .logo{
    background:url(../images/cuc_tcc-logo_wht_352x20.png) no-repeat;
        background-size: 100% auto;
        }
}
/****************** DROMPASKY SEARCH HACKS *********************/

td.gsc-search-button {
  padding-bottom: 10px;
}

td.gsc-clear-button {
  padding-bottom: 10px;
}

/****************** END DROMPASKY SEARCH HACKS *****************/


/*
div.js_hidden_on_start{
  float:right;
  margin:0 0 20px 20px;
  text-align:center;
}
div.js_hidden_on_start div.flexComp, div.js_hidden_on_start div.flexCompImg{
  margin:0 0 20px;
}
@media only screen and (max-width: 560px){
  div.js_hidden_on_start{
    float:none;
    margin:0 0 20px;
    }
}*/


/**************************** jQuery rt Responsive Tables - v1.0.2 Start ****************************/
/*
Generic Styling, for Desktops/Laptops
*/
table.rt-responsive-table {
  width: 100%;
  border-collapse: collapse;
}
/* Zebra striping */
table.rt-responsive-table tr:nth-of-type(odd) {
  background: #eee;
}
table.rt-responsive-table th {
  background: #333;
  color: white;
  font-weight: bold;
}
table.rt-responsive-table td, table.rt-responsive-table th {
  padding: 6px;
  border: 1px solid #ccc;
  text-align: left;
}



/* Force table to not be like tables anymore */
table.rt-responsive-table.rt-vertical-table, table.rt-responsive-table.rt-vertical-table thead, table.rt-responsive-table.rt-vertical-table tbody, table.rt-responsive-table.rt-vertical-table th, table.rt-responsive-table.rt-vertical-table td, table.rt-responsive-table.rt-vertical-table tr {
  display: block;
  overflow: hidden;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  /* BELOW THREE STATEMENTS NEEDED FOR IE9 AND BELOW */
  float:left;
  clear:left;
  width: 100%;
}

/* Hide table headers (but not display: none;, for accessibility) */
table.rt-responsive-table.rt-vertical-table thead tr {
  position: absolute;
  top: -9999px;
  left: -9999px;
}

table.rt-responsive-table.rt-vertical-table tr { border: 1px solid #ccc; }

table.rt-responsive-table.rt-vertical-table td {
  /* Behave  like a "row" */
  border: none;
  border-bottom: 1px solid #eee;
  position: relative;
  padding-left: 50%;
}

table.rt-responsive-table.rt-vertical-table td:before {
  /* Now like a table header */
  position: absolute;
  /* Top/left values mimic padding */
  top: 6px;
  left: 6px;
  width: 45%;
  padding-right: 10px;
  white-space: nowrap;
  font-weight: bold;
}
/**************************** jQuery rt Responsive Tables - v1.0.2 End ****************************/

.custom_nav {
  vertical-align: top;
  font-weight: bold;
  border-top: 2px solid #ccc;
  padding: 8px 0 26px 1px;
  a {
    color: #8a8560;
    outline: none;
    text-transform: uppercase;
  }
}
.custom_nav_top {
  width: 33%;
  float: left;
  a {
    font-size: 16px;
  }
  @media only screen and (max-width: 767px) {
   width: 100%;
   float: none;
   color: $white;
  font-size: 14px;
  .menu-item {
    padding: 10px 0 9px;
  border-bottom: 1px solid #e0e1e2;
  }
  }

}

/* Custom post */
.alignleft {
    float: left;
    margin: 5px 26px 0 0;
    padding-bottom: 20px;
}

/* Navigation custom styles */

.sub-menu {
  display: none; // do not display on initial load
  li {
    a {
      text-transform: none;
    }
  }
  .custom_nav {
    border-top: none;
  }
}

#menu-top-navigation ul {
  list-style: none;
  li {
    color: $white;
    font-size: 14px;
    padding: 10px 0 9px;
    border-bottom: 1px solid #e0e1e2;
  }
}

/**
 * 12.1 - Captions
 */

.wp-caption {
  margin-bottom: 1.75em;
  max-width: 100%;
}

.wp-caption img[class*="wp-image-"] {
  display: block;
  margin: 0;
}

.wp-caption .wp-caption-text {
  text-align: left;
  display: block;
  padding: 5px 0 0 0;
  margin-bottom: 5px;
  color: #808080;
  font-size: 12px;
  line-height: 20px;
}

.featured {
  min-height: 361px;
}

/* Hide Social media post widget title */
.widgettitle {
 display: none;
}

/* Style buttons for Edit This */
.btn-group {
  margin: 10px 0;
}
#content a, #content a:hover, #content a:active {
  text-decoration: underline;
  &.post-edit-link{
    text-decoration: none;
    color: #333;
    display: inline-block;
    &:hover {
      color: #b3b3b3;
    }
  }
}

table.rt-responsive-table-0.rt-vertical-table td:nth-of-type(1):before {
    content: "Name";
}

table.rt-responsive-table-0.rt-vertical-table td:nth-of-type(2):before {
    content: "Department";
}

table.rt-responsive-table-0.rt-vertical-table td:nth-of-type(3):before {
    content: "College";
}

table.rt-responsive-table-0.rt-vertical-table td:nth-of-type(4):before {
    content: "Campus Address";
}

table.rt-responsive-table-0.rt-vertical-table td:nth-of-type(5):before {
    content: "Email";
}

table.rt-responsive-table-0.rt-vertical-table td:nth-of-type(6):before {
    content: "Phone";
}

/* Btn opener dropdown */

@media only screen and (max-width: 767px){
  .navigation-block .btn-opener:before {
      width: 31px;
      height: 9px;
      background-size: 31px 9px;
      bottom: -7px;
      left: 10px;
  }
  .side-nav ul li ul ul li {
      padding: 0;
  }
  .side-nav ul li ul a {
    font-size: 13px;
    padding: 0;
    display: block;
    color: #8a8560;
    line-height: 30px;
}
  .side-nav ul li ul ul a {
    color: #8a8560;
    font-weight: normal;
    padding: 0;
    margin: 0;
    padding: 5px 1px;
    font-weight: bold;
    font-size: 12px;
    line-height: 28px;
  }
  .side-nav ul li ul li.expanded > a, .side-nav ul li ul a:hover {
    background: none;
    text-decoration: none;
    background: url(../images/arrow7.png) no-repeat 99% center;
  }
  .side-nav .custom_nav {
    // border-top: 2px solid #ccc;
  }
  body {
    padding: 0 !important;
  }
}

/* Responsive Google Calendar */
/* Responsive iFrame */
 
.responsive-iframe-container {
    position: relative;
    padding-bottom: 56.25%;
    padding-top: 30px;
    height: 0;
    overflow: hidden;
}
 
.responsive-iframe-container iframe,   
.vresponsive-iframe-container object,  
.vresponsive-iframe-container embed {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
}

/* Responsive Youtube videos */
.cuc-video-container{
  position:relative;
  padding-bottom:56.25%;
  padding-top:30px;
  height:0;
  overflow:hidden;
}
.cuc-video-container-outer{
  max-width: 640px;
}
.cuc-video-container iframe,.cuc-video-container object,.cuc-video-container embed{
  position:absolute;
  top:0;
  left:0;
  width:100%;
  height:100%;
}

// Social icon mobile styling
@media only screen and (max-width: 767px){
  span {
    vertical-align: middle;
    line-height: 30px;
  }
  .socicon {
    text-align: center;
    &:before {
      font-size: 12px;
    }
  }
  .social-icons li a {
    float: left;
    width: 20px;
    height: 30px;
  }
  .zoom-social-icons-list .socicon, .zoom-social-icons-list .dashicons, .zoom-social-icons-list .genericon {
      padding: 2px;
      -moz-box-sizing: content-box;
      -webkit-box-sizing: content-box;
      box-sizing: content-box;
      -moz-transition: opacity .2s;
      -webkit-transition: opacity .2s;
      transition: opacity .2s;
      top: 0px;
      vertical-align: middle;
      width: 1em;
      height: 1em;
      line-height: 1em;
      font-size: 1.2em;
  }
}

@media only screen and (min-width: 768px){
  .zoom-social-icons-list .socicon, .zoom-social-icons-list .dashicons, .zoom-social-icons-list .genericon {
      padding: 5px;
      -moz-box-sizing: content-box;
      -webkit-box-sizing: content-box;
      box-sizing: content-box;
      -moz-transition: opacity .2s;
      -webkit-transition: opacity .2s;
      transition: opacity .2s;
      top: 0px;
      vertical-align: middle;
      width: 1em;
      height: 1em;
      line-height: 1em;
      font-size: 1.2em;
  }
  .social-icons li a {
    float: left;
    width: 25px;
    height: 30px;
  }
    .socicon {
    text-align: center;
    &:before {
      font-size: 16px;
    }
  }
}

// Accessibility fixes by Nathan S.
#footer, #footer a {
  color: #000 !important;
}

a {
  text-decoration: underline !important;
}

a:hover {
  text-decoration: none !important;
}

.side-nav a, .navigation-block a.btn-opener, .featureText a.learn {
 text-decoration: none !important; 
}

.visual-panel h2, #content .heading-box h1, .side-nav .menu-side-navigation-container > ul > li > a, .side-nav ul li ul ul a {
  color: #797653 !important;
}

.navigation-block > a:focus, .side-nav a:focus, a.addthis_button_compact:focus {
    outline: thin dotted #333;
    outline: 5px auto -webkit-focus-ring-color;
    outline-offset: -2px;
}

.drop-down {
    background: #797653 !important;
}

.inner-text a, #interiorCopy a {
 color: #0171bb !important; 
}

.inner-text a:hover, #interiorCopy a:hover, #home-body-copy a:hover {
 color: #0199fe !important; 
}

#menu-top-navigation a:hover {
  color: #ccc !important;
}

.wp-caption .wp-caption-text {
  color: #757575 !important;
}

.screen-reader-text {
  position: absolute;
  left: -10000px;
  top: auto;
  width: 1px;
  height: 1px;
  overflow: hidden;
}

.zoom-social_icons-list-span {
  padding: 5px !important;
}